import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/components/HelloWorld.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/ad',
    name: 'ad',
    component: index,
  },
  {
    path: '/',
    redirect: '/ad', // 如果路径为空没有指向，那么就跳转到首页去，redirect这里面写的就是跳转的地址
  },
]

const router = new VueRouter({
  routes,
  mode: 'history', // hash
})

export default router
