<template>
  <div class="whole">
    <div class="box">
      <img :src="img" class="img" v-if="route != 'customer_h5'" @click="toPage()" />
      <div class="cont" v-if="route == 'customer_h5'" v-html="content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      img: '',
      id: '',
      group_id: '',
      hub: '',
      code: '',
      path: '',
      params: [],
      search: '',
      route: '',
      content: ''
    }
  },
  async created() {
    let url = window.location.href
    console.log(new URL(url))
    let hash = new URL(url).hash
    let cs = hash.replace('#', '').replace('/index', '').replace('?', '')
    if(cs) {
      this.params.push(...cs.split('&'))
    }
    
    let search = new URL(url).search
    this.search = search;
    let cs1 = search.replace('#', '').replace('/', '').replace('?', '')
    if(cs1) {
      this.params.push(...cs1.split('&'))
    }
    console.log(this.params)
    for (let index = 0; index < this.params.length; index++) {
      let element = this.params[index]
      if(element.indexOf('code=') >= 0) {
        this.code = element.split('=')[1]
        await this.getInfo()
        this.getPath()
        break
      }
    }
  },
  methods: {
    // https://g.ii090.com/index/applet/getImages?group_id=
    async getInfo() {
      await this.$axios.get('/api/advertise/h5/' + this.code).then((res) => {
        this.img = res.data.data.h5_image
        this.route = res.data.data.route
        this.content = res.data.data.content
        document.title = res.data.data.h5_title || ''
      })
    },
    getPath() {
      let pms = {}
      for (let index = 0; index < this.params.length; index++) {
        let element = this.params[index]
        if(element.split('=')[0] != 'code') {
          pms[element.split('=')[0]] = element.split('=')[1]
        }
      }
      this.path = '/api/advertise/scheme/' + this.code + this.search;
      console.log(this.path);
      //this.$axios.get('/api/advertise/scheme/' + this.code, {params: pms}).then((res) => {
        //this.path = res.data.data.scheme
      //})
    },
    toPage() {
      if(this.path) {
        window.location.href = this.path
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .whole{
    width: 100%;
    height: auto;
    .box{
      width: 100%;
      .img{
        max-width: 100%;
      }
      .cont{
        word-break: break-word;
        padding: 0 12px;
      }
    }
  }
</style>
<style scoped>
  ::v-deep .cont img{max-width: 100%;}
</style>
